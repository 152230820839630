export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBnTBzsG7e-MVLS6CwxUW0_81WQnMqeaZc',
    authDomain: 'wayaj-staging-260218.firebaseapp.com',
    databaseURL: 'https://wayaj-staging-260218.firebaseio.com',
    projectId: 'wayaj-staging-260218',
    storageBucket: 'wayaj-staging-260218.appspot.com',
    messagingSenderId: '100079506045',
    appId: '1:100079506045:web:4322371436942ed81cd714',
    dashboardLink: 'https://wayaj-staging-260218.web.app/'
  },
  api: 'https://us-central1-wayaj-staging-260218.cloudfunctions.net/api'
};
