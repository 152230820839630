import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'member-hotels',
    loadChildren: () => import('./modules/static-page/member-hotels/member-hotels.module').then(m => m.MemberHotelsModule)
  },
  {
    path: 'imppact-for-business',
    loadChildren: () => import('./modules/static-page/landing-page/landing-page.module').then(m => m.LandingPageModule)
  },
  {
    path: 'wayaj-mission-values-team-partners',
    pathMatch: 'full',
    loadChildren: () => import('./modules/static-page/about/about.module').then(m => m.AboutModule)
  },
  {
    path: 'sustainable-hotel-certification',
    loadChildren: () => import('./modules/static-page/hotel-sustainability-certification/hotel-sustainability-certification.module').then(m => m.HotelSustainabilityCertificationModule)
  },
  {
    path: 'hotel-sustainability-marketing',
    loadChildren: () => import('./modules/static-page/hotel-sustainability-marketing/hotel-sustainability-marketing.module').then(m => m.HotelSustainabilityMarketingModule)
  },
  {
    path: 'green-hotel-rating',
    loadChildren: () => import('./modules/static-page/hotel-sustainability-marketing2/hotel-sustainability-marketing2.module').then(m => m.HotelSustainabilityMarketing2Module)
  },
  // {
  //   path: 'imppact',
  //   loadChildren: () => import('./modules/static-page/imppact/imppact.module').then(m => m.ImppactModule)
  // },
  {
    path: 'imppact',
    loadChildren: () => import('./modules/static-page/imppact-v2/imppact-v2.module').then(m => m.ImppactV2Module)
  },
  {
    path: 'hotel-sustainability-rating',
    loadChildren: () => import('./modules/static-page/hotel-sustainability-rating/hotel-sustainability-rating.module').then(m => m.HotelSustainabilityRatingModule)
  },
  {
    path: 'sustainable-hospitality-tools',
    loadChildren: () => import('./modules/static-page/member/member.module').then(m => m.MemberModule)
  },
  {
    path: 'hotel-carbon-footprint-calculator',
    loadChildren: () => import('./modules/static-page/cfc-engine/cfc-engine.module').then(m => m.CfcEngineModule)
  },
  {
    path: 'carbon-footprint-calculator',
    loadChildren: () => import('./modules/static-page/carbon-footprint-calculator/carbon-footprint-calculator.module').then(m => m.CarbonFootprintCalculatorModule)
  },
  {
    path: 'ambassador',
    loadChildren: () => import('./modules/partners-page/ambassador/ambassador.module').then(m => m.AmbassadorModule)
  },
  {
    path: 'recommend',
    loadChildren: () => import('./modules/partners-page/recommend/recommend.module').then(m => m.RecommendModule)
  },
  {
    path: 'invite',
    loadChildren: () => import('./modules/partners-page/invite/invite.module').then(m => m.InviteModule)
  },
  { 
    path: 'hotel-sustainability-compliance', 
    loadChildren: () => import('./modules/static-page/hotel-sustainability-compliance/hotel-sustainability-compliance.module').then(m => m.HotelSustainabilityComplianceModule) 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
